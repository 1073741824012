.p-notfound__body {
  padding: 100px 20px 60px; }

.p-notfound__en {
  font-size: 1rem;
  text-transform: capitalize;
  color: #242422; }

.p-notfound__txt {
  margin-top: 2rem; }

.p-notfound__txt a {
  text-decoration: underline; }

@media screen and (min-width: 1024px) {
  .p-notfound {
    padding-top: 80px; }

  .p-notfound__body {
    padding: 200px 0;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto; }

  .p-notfound__body h1 {
    font-size: 4rem; }

  .p-notfound__en {
    font-size: 1.4rem;
    text-transform: capitalize;
    color: #242422; }

  .p-notfound__txt {
    margin-top: 2rem;
    font-size: 2rem; }

  .p-notfound__txt a {
    text-decoration: underline; } }
